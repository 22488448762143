const IMAGES = {
  // LOGO
  //HOME
  banner1: require("../img/home/banner1.jpg"),
  banner2: require("../img/home/banner2.jpg"),
  banner3: require("../img/home/banner3.jpg"),
  banner4: require("../img/home/banner4.png"),
  // barcode
  barcode: require("../img/barcode.jpg"),
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
  //
  maint: require("./2.jpg"),
  // pinfo page
  phonepe: require("../img/products/phonepe.png"),
  gpay: require("../img/products/gpay.png"),
  paytm: require("../img/products/paytm.png"),
  // support
  support: require("../img/contact/supportt.png"),
  // home slider
  paytm: require("../img/home/paytm.webp"),
  gpay: require("../img/home/gpay.webp"),
  phonepe: require("../img/home/phonepe.webp"),
  upi: require("../img/home/upi.webp"),
};

export default IMAGES;
